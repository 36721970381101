*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 10px;
	font-family: 'Roboto', sans-serif;
}
body {
	background: #fff;
	padding: 0 3rem;
}
label {
	display: block;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
label {
	display: block;
	width: max-content;
}

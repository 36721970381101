@import './../../Variables.scss';

.url-input {
	display: block;
	width: 100%;
	height: 5rem;
	border-radius: 2rem;
	padding: 1rem;
	border: none;
	border: 1px solid $color-secondary;
	background-color: $color-tertiary;
	transition: 0.1s all ease-in-out;
	font-size: 1.5rem;
	margin-bottom: 2rem;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
	&:focus {
		border-color: $color-primary;
		outline: none;
	}
}

.btn {
	display: inline-flex;
	text-decoration: none;
	color: $color-secondary;
	justify-content: center;
	align-items: center;
	max-width: 20.8rem;
	height: 5rem;
	width: 100%;
	margin-right: 2rem;
	font-size: 2rem;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
	border-radius: 1rem;
	border: 1px solid $color-secondary;
	background: $color-tertiary;
	cursor: pointer;

	&:active {
		transform: translateY(2px) scale(0.99);
		box-shadow: none;
	}
	&--container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 2rem;
	}
}
.dropdown {
	position: relative;
	user-select: none;

	.text {
		font-size: 2rem;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		position: relative;
		cursor: pointer;
		width: 100%;
		svg {
			width: 2rem;
			height: 2rem;

			transition: 0.4s all ease;
		}
		.arrow-up {
			transform: rotate(180deg);
		}
	}

	&-content {
		position: absolute;
		top: calc(100% - 7px);
		width: 100%;
		left: 0;
		transform: translateX(-1px);
		box-sizing: content-box;
		z-index: 1;
		background-color: $color-tertiary;

		border: 1px solid $color-secondary;
		border-top: none;
		border-radius: 0 0 2rem 2rem;
		text-align: left;
		overflow: hidden;
	}
	&-item {
		padding: 12px 3rem;
		font-size: 2rem;
		text-decoration: none;
		display: block;
		color: $color-secondary;
		&:hover {
			background-color: $color-primary;
			color: $color-tertiary;
		}
	}
}
#forceupdate {
	display: none;
	&:checked + .forceupdate {
		background-color: $color-primary;
		color: $color-tertiary;
		border: 1px solid $color-primary;
	}
}
.forceupdate {
	user-select: none;
	transition: all 0.2s ease;
}

$color-primary: #636cff;
$color-secondary: #212121;
$color-tertiary: #fff;
$color-google: #4285f4;
$color-danger: #df2935;
$box-shadow-1: 0 -1px 4px 0 rgba(0, 0, 0, 0.1),
	inset 0 0 4px 0 rgba(0, 0, 0, 0.1), inset 0 4px 4px 0 #fff,
	inset 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
$box-shadow-1-hover: inset 1px 5px 4px 0 rgb(0 0 0 / 10%),
	inset -3px -3px 5px 0px rgb(0 0 0 / 5%), inset -6px 4px 0px 4px #fff;

@import '../../Variables.scss';
.card {
	display: flex;
	max-width: 67rem;
	padding: 1.6rem;
	border-radius: 2rem;
	border: 1px solid $color-secondary;
	width: 49%;
	margin: 0.8rem 0;

	&__container {
		display: flex;
		margin-top: 4.5rem;
		padding: 0 4.6rem;
		flex-wrap: wrap;
		justify-content: space-around;
	}
	&--image {
		max-width: 22.3rem;

		width: 100%;

		overflow: hidden;
		img {
			border-radius: 2rem;
			width: 100%;
			height: auto;
		}
	}
	&--content {
		padding: 0 4.6rem;
		color: $color-secondary;
		height: 100%;
		width: 100%;

		text-align: left;
		h3 {
			display: flex;
			align-items: center;

			margin-bottom: 1.2rem;
			width: 100%;

			a {
				color: rgba($color-secondary, 0.8);
				text-decoration: none;
				font-size: 3rem;
				font-family: 'Playfair Display', serif;
				cursor: pointer;

				margin-right: 1.5rem;
				&::selection {
					background: $color-secondary;
					color: $color-tertiary;
				}
				&:hover {
					color: $color-secondary;
				}
			}
			span {
				font-size: 1.2rem;
				color: $color-tertiary;
				background-color: $color-primary;
				padding: 0.5rem 1rem;

				border-radius: 2rem;
			}
		}
		p {
			font-size: 1.6rem;
			margin-bottom: 3px;
			line-height: 1.2;
			a {
				font-size: 1.6rem;
				color: rgba($color-secondary, 0.8);
				text-decoration: none;
				&:hover {
					color: $color-secondary;
				}
			}
		}
		.description {
			margin-bottom: 1.7rem;
		}
		.btns {
			width: 100%;
			display: flex;
			button,
			a {
				max-width: 18rem;
				width: 100%;
				height: 4.2rem;
				font-size: 1.7rem;
			}
		}
	}
}

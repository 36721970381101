@import './../../Variables.scss';

.logs {
	&-Heading {
		font-size: 2.5rem;
		margin-bottom: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
		svg {
			width: 2rem;
			height: 2rem;
			margin-right: 1rem;
			transition: 0.4s all ease;
			cursor: pointer;
			margin: 1rem;
		}
	}
	&--container {
		padding: 0 15.7rem;
		div {
			height: 33.8rem;
			border: 1px solid $color-secondary;
			border-radius: 1rem;
			overflow-y: scroll;
			&::-webkit-scrollbar {
				width: 1rem;
				border-radius: 1rem;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $color-primary;
				border-radius: 1rem;
				transform: translateX(-3px);
			}
			p {
				font-size: 1.5rem;
				margin: 0;
				padding: 1rem;
				border-bottom: 1px solid $color-secondary;
				&:last-child {
					border-bottom: none;
				}
				* {
					font-size: 1.5rem;
					color: $color-primary;
				}
			}
		}
	}
}

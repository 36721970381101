@import '../../Variables.scss';
.input {
	width: 100%;
	height: 7rem;
	margin-bottom: 2rem;
	border-radius: 1rem;
	border: 1px solid $color-secondary;
	font-size: 2rem;
	padding: 1.4rem;
	&:focus {
		outline: none;
		border-color: $color-primary;
	}
}
.signup__btn {
	width: 100%;
	max-width: 40rem;
	margin: 0;
	margin-bottom: 2rem;
	&--container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}
	&--google {
		background-color: $color-google;
		color: $color-tertiary;
		border: none;
		font-size: 2rem;
		padding: 1.4rem;
		border-radius: 1rem;
		border: 1px solid $color-secondary;
		// &:hover {
		// 	background-color: $color-google-hover;
		// }
	}
}
.signup--container {
	margin-top: 7.6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	label {
		width: 100%;

		max-width: 40rem;
	}
}
